const updateCodeSetting = async (data) => {
    try {
        const session_id = localStorage.getItem('session_id');
        const response = await fetch('https://api.pemont-insight.com/settings', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            },
            body: data
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

const convertToCSV = (productCodes) => {
    // CSV의 헤더 생성
    const headers = [
        'Code',
        'Name',
        'Local Cost',
        'Currency Rate',
        'MUSHINSA',
        'CAFE24',
        'W_CONCEPT',
        'OCO',
        'CM29',
        'EQL',
        'KREAM',
        'CAFE24_PRT'
    ];

    // 각 제품 데이터를 CSV의 행으로 변환
    const rows = productCodes.map(product => [
        product.code,
        product.name,
        product.localCost,
        product.currencyRate,
        product.MUSHINSA,
        product.CAFE24,
        product.W_CONCEPT,
        product.OCO,
        product.CM29,
        product.EQL,
        product.KREAM,
        product.CAFE24_PRT
    ].join(','));

    // 헤더와 데이터 행들을 합쳐서 최종 CSV 문자열 생성
    return [headers.join(','), ...rows].join('\n');
};

export { updateCodeSetting, convertToCSV };