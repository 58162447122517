import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Form, FormControl, Button, Spinner } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { login, logout, redirectSessionOut } from '../api/Auth';

function CustomNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [credentials, setCredentials] = useState({ id: '', pw: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const navigate = useNavigate();

  // 유효 시간 만료 체크 및 리다이렉트 처리
  useEffect(() => {
    const expiry = localStorage.getItem("expiry");
    const currentTime = new Date().getTime();

    if (expiry && currentTime > expiry) {
      setIsLoggedIn(false);
      redirectSessionOut(navigate);
    } else {
      const session_id = localStorage.getItem('session_id');
      if (session_id) {
        setIsLoggedIn(true);
        setUsername(localStorage.getItem('user_name'));
      }
    }
  }, [navigate]);

  const handleLogin = async () => {
    setLoginLoading(true);
    const result = await login(credentials.id, credentials.pw);

    if (result.success) {
      setUsername(result.userName);
      setIsLoggedIn(true);
      setErrorMessage('');
    } else {
      setErrorMessage(result.message);
    }

    setCredentials({ id: '', pw: '' });
    setLoginLoading(false);
  };

  const handleLogout = () => {
    logout(navigate, "/");
  };

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">PEMONT INSIGHT</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/dashboard">
              <Nav.Link>Dashboard</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/stocks">
              <Nav.Link>Stocks</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/prediction">
              <Nav.Link>Prediction</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/data">
              <Nav.Link>Training Data</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/settings">
              <Nav.Link>Setting</Nav.Link>
            </LinkContainer>
          </Nav>
          {isLoggedIn ? (
            <Nav className="ms-auto">
              <Navbar.Text className="me-3">
                Signed in as: <a href="#login">{username}</a>
              </Navbar.Text>
              <Button variant="outline-danger" onClick={handleLogout}>Logout</Button>
            </Nav>
          ) : (
            <Form className="d-flex ms-auto" onKeyPress={(e) => { if (e.key === 'Enter') handleLogin(); }}>
              <FormControl
                type="text"
                placeholder="Username"
                className="me-2"
                aria-label="Username"
                name="id"
                value={credentials.id}
                onChange={handleChange}
              />
              <FormControl
                type="password"
                placeholder="Password"
                className="me-2"
                aria-label="Password"
                name="pw"
                value={credentials.pw}
                onChange={handleChange}
              />
              <Button variant="outline-success" onClick={handleLogin}>Login</Button>
            </Form>
          )}
          {loginLoading && <Spinner animation="border" variant="light" />}
          {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
