
export const retrieveLatestPredictResult = async () => {
    const session_id = localStorage.getItem('session_id');
    try {
        const response = await fetch('https://api.pemont-insight.com/latest_predict_result', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};