export const RetrieveCollectStockStatus = async (message_id) => {
    try {
        const query = new URLSearchParams({message_id: message_id});
        const session_id = localStorage.getItem('session_id');
        const response = await fetch(`https://api.pemont-insight.com/collect/stocks/status?${query.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}