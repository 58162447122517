import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the required components
ChartJS.register(ArcElement, Tooltip, Legend);

function CircleChart({ data }) {
  // 사전형 데이터를 처리: 각 상품의 이름을 라벨로 사용하고, 그 상품의 월별 판매 합계를 값으로 사용
  const labels = Object.keys(data);
  const values = labels.map(label => 
    // 해당 상품의 월별 판매 데이터를 모두 합산하여 총합을 계산
    data[label].reduce((total, num) => total + parseInt(num), 0)
  );

  const totalValue = values.reduce((acc, value) => acc + value, 0); // 전체 합계 계산

  // 1% 미만의 비중을 가진 항목을 "기타등등"으로 묶기
  const processedData = values
    .map((value, index) => ({ label: labels[index], value, percentage: (value / totalValue) * 100 }))
    .sort((a, b) => b.value - a.value);

  const topData = processedData.filter(item => item.percentage > 1);  // 1% 이상의 항목만 선택
  const otherData = processedData.filter(item => item.percentage <= 1); // 1% 이하의 항목은 묶음 처리

  const otherTotal = otherData.reduce((acc, item) => acc + item.value, 0); // 기타 등등의 합계

  const finalLabels = topData.map(item => item.label).concat(otherTotal > 0 ? ['기타등등'] : []);
  const finalValues = topData.map(item => item.value).concat(otherTotal > 0 ? [otherTotal] : []);

  const chartData = {
    labels: finalLabels,  // 동적으로 생성된 라벨을 사용
    datasets: [
      {
        label: 'Sales',
        data: finalValues,  // 동적으로 생성된 값을 사용
        hoverOffset: 4
      }
    ]
  };

  return <Doughnut data={chartData} />;
}

export default CircleChart;
