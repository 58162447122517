import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import StackedLineChart from '../components/StackedLineChart';
import { redirectSessionOut } from '../api/Auth';
import { retrieveLatestPredictResult } from '../api/RetrieveLatestPredictResult';
import { predictProportion } from '../api/PredictProportion';
import { useNavigate } from 'react-router-dom';


const convertDataForGraph = (sourceData, startDate, endDate) => {
  const dataMap = new Map();
  let labels = [];
  let productNames = [];
  const datasets = [];

  const start = new Date(startDate);
  const end = new Date(endDate);

  if (sourceData != null) {
    // Labeling
    for (const index in sourceData) {
      const item = sourceData[index];
      const checkDate = new Date(item.date);

      if (checkDate >= start && checkDate <= end) {
        labels.push(item.date);
        productNames.push(item.product_name);
      }
    }

    labels = [...new Set(labels)];
    productNames = [...new Set(productNames)];

    // Organize data
    productNames.forEach(productName => {
      let data = [];

      labels.forEach(label => {
        let targetItem = null;
        sourceData.forEach(item => {
          if (item.product_name === productName && item.date === label) {
            targetItem = item;
            return;
          }
        });

        data.push(targetItem ? parseInt(targetItem.product_sales) : 0);
      });

      dataMap.set(productName, data);
    });
  }

  dataMap.forEach((value, key) => {
    datasets.push({
      label: key,
      data: value,
      borderWidth: 1,
    });
  });

  return {
    labels,
    datasets,
  };
};

function Prediction() {
  const [logs, setLogs] = useState("");
  const [predictDate, setPredictDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [lastPredictData, setLastPredictData] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const sortByDate = (a, b) => new Date(a.date) - new Date(b.date);

    const fetchData = async () => {
      try {
        const result = await retrieveLatestPredictResult();
        result["result"].sort(sortByDate);
        setLastPredictData(result["result"]);

        // Generate the date options dynamically
        const dateSet = new Set(result["result"].map(item => item.date));
        const dateArray = Array.from(dateSet).sort();

        if (dateArray.length > 0) {
          setDateOptions(dateArray);
          setStartDate(dateArray[0]);
          setEndDate(dateArray[dateArray.length - 1]);
        }
        setLoading(false);

      } catch (error) {
        if (error.message === 'Unauthorized') {
          redirectSessionOut(navigate);
        } else {
          console.error('Error fetching prediction data:', error);
        }
      }
    };

    fetchData();
  }, [navigate]);

  // Update predictDate to be one month after endDate
  useEffect(() => {
    if (endDate) {
      const [year, month] = endDate.split('/').map(Number); // Safely split year and month from endDate

      if (!isNaN(year) && !isNaN(month)) {
        let newMonth = month + 1;
        let newYear = year;

        if (newMonth > 12) {
          newMonth = 1;
          newYear += 1;
        }

        const formattedDate = `${newYear}/${newMonth < 10 ? '0' + newMonth : newMonth}`;
        setPredictDate(formattedDate);
      }
    }
  }, [endDate]);

  // 데이터가 변경되거나 startDate 또는 endDate가 변경될 때만 차트 데이터를 업데이트
  useEffect(() => {
    if (lastPredictData.length > 0) {
      const filteredData = convertDataForGraph(lastPredictData, startDate, endDate);
      setChartData(filteredData);
    }
  }, [lastPredictData, startDate, endDate]);

  const handlePredict = async () => {
    setUpdating(true);
    try {
      // Call the predictProportion function
      const result = await predictProportion(predictDate);
      setLogs(result);

      // Fetch the latest prediction results
      const latestResult = await retrieveLatestPredictResult();

      // Sort by date
      latestResult["result"].sort((a, b) => new Date(a.date) - new Date(b.date));

      // Update state
      setLastPredictData(latestResult["result"]);

      const dateSet = new Set(latestResult["result"].map(item => item.date));
      const dateArray = Array.from(dateSet).sort();

      if (dateArray.length > 0) {
        setDateOptions(dateArray);
        setStartDate(dateArray[0]);
        setEndDate(dateArray[dateArray.length - 1]);
      }

      // Update the chart and logs
      const filteredData = convertDataForGraph(latestResult["result"], startDate, endDate);
      setChartData(filteredData);
      setLogs(latestResult["summary"]);
      setUpdating(false);

    } catch (error) {
      if (error.message === 'Unauthorized') {
        redirectSessionOut(navigate);
      } else {
        console.error('Error fetching prediction data:', error);
      }
    }
  };

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <Container>
      <div className="m-3">
        <h2>Predicted Sales Volume Trends</h2>

        <Row className="align-items-center mb-3">
          <Col>
            <Form.Group controlId="startDateDropDown">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                as="select"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              >
                {dateOptions.map((date, idx) => (
                  <option key={idx} value={date}>{date}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="endDateDropDown">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                as="select"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              >
                {dateOptions.map((date, idx) => (
                  <option key={idx} value={date}>{date}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="predict_date_form">
              <Form.Label>Predict Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="YYYY/MM"
                value={predictDate}
                onChange={(e) => setPredictDate(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col>
            <Button id="predict_button" onClick={handlePredict}>
              Predict
            </Button>
            {updating && <Spinner animation="border" role="status" />}
          </Col>
        </Row>

        <StackedLineChart data={chartData} />
      </div>

      <Card className="mb-4">
        <Card.Body>
          <Card.Title>API Logs</Card.Title>
          <Row className="mb-3 justify-content-end">
            <Col xs="auto">
              <Button variant="danger" onClick={() => setLogs('')}>Clear</Button>
            </Col>
          </Row>
          <textarea
            className="form-control"
            rows="10"
            value={logs}
            readOnly
          />
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Prediction;
