export const retrieveStocks = async (timestamp) => {
    const session_id = localStorage.getItem('session_id');
    const params = new URLSearchParams({ timestamp: timestamp });
    const queryString = params.toString();
    try {
        const response = await fetch(`https://api.pemont-insight.com/stocks?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
};