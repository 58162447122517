class StockItem {
    constructor(platform, qty) {
        this.platform = platform;
        this.qty = qty;
    }

    updateQty(newQty) {
        this.qty = newQty;
    }
}

class StockRecord {
    constructor(id, year, month, name, code, size, offlineStockQty, safeStockQty, nextOrderQty, stockItems) {
        this.id = id;
        this.year = year;
        this.month = month;
        this.name = name;
        this.code = code;
        this.size = size;
        this.offlineStockQty = offlineStockQty;
        this.safeStockQty = safeStockQty;
        this.nextOrderQty = nextOrderQty;
        this.stockItems = stockItems;
    }

    addStockItem(stockItem) {
        this.stockItems.push(stockItem);
    }

    getStockItem(index) {
        return this.stockItems[index] || null;
    }

    getStockItemByPlatform(platform) {
        return this.stockItems.find((stockItem) => stockItem.platform === platform) || null;
    }

    updateSafeStockQty(safeStockQty) {
        this.safeStockQty = safeStockQty;
    }

    updateNextOrderQty(nextOrderQty) {
        this.nextOrderQty = nextOrderQty;
    }

    getAllStockItems() {
        return this.stockItems;
    }

    updateDate(year, month) {
        this.year = year;
        this.month = month;
    }

    // 데이터 변환을 위한 정적 메서드
    static fromData(data) {
        const { id, code, month, name, offlineStockQty, nextOrderQty, safeStockQty, size, stockItems } = data;

        // 변환된 stockItems 배열
        const stockItemsTransformed = stockItems.map(item => new StockItem(item.platform, parseInt(item.qty, 10)));

        // StockRecord 생성 후 반환
        return new StockRecord(
            id,
            2024, // 연도는 고정
            parseInt(month, 10), // month를 숫자로 변환
            name,
            code,
            size,
            parseInt(offlineStockQty, 10),
            parseInt(safeStockQty, 10),
            parseInt(nextOrderQty, 10),
            stockItemsTransformed
        );
    }
}

export { StockItem, StockRecord };
