const login = async (id, pw) => {
    try {
        const response = await fetch("https://api.pemont-insight.com/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id, pw })
        });

        if (response.status === 200) {
            const data = await response.json();
            const now = new Date();
            localStorage.setItem("expiry", now.getTime() + 60 * 60 * 1000 + 24); // 24 hours
            localStorage.setItem("session_id", data.session_id);
            localStorage.setItem("user_name", data.user_name);
            return { success: true, userName: data.user_name };
        } else if (response.status === 401) {
            const data = await response.json();
            return { success: false, message: data.message };
        } else {
            return { success: false, message: "Unknown Error" };
        }
    } catch (error) {
        console.error("Login Error:", error);
        return { success: false, message: "An error occurred during login." };
    }
};

const logout = async(navigate, url) =>{
   localStorage.removeItem('session_id');
   localStorage.removeItem('expiry');
   localStorage.removeItem('user_name');
   window.location.href = url;
}

const redirectSessionOut = (navigate) => {
    logout(navigate, "/error?message=Session expired. Please log in again.");
}

export { login, logout, redirectSessionOut };