import React from 'react';
import './ErrorPage.css';
import { useLocation } from 'react-router-dom';

function ErrorPage() {
  const location = useLocation();

  // URL에서 쿼리 파라미터를 추출하는 함수
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };

  // 'message'라는 쿼리 파라미터를 가져옴, 기본 메시지를 설정
  const errorMessage = getQueryParam('message') || "Oops! Something went wrong.";

  // XSS 공격 방지를 위한 간단한 HTML 이스케이프 함수
  const sanitize = (str) => {
    return str.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  return (
    <div className="centered-container">
      <h1>Error</h1>
      <p>{sanitize(errorMessage)}</p>
      <a href='/' className="error-button">Go to Home</a>
    </div>
  );
}

export default ErrorPage;
