import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Spinner } from 'react-bootstrap';
import CircleChart from '../components/CircleChart';
import LineChart from '../components/LineChart';
import PeriodLineChart from '../components/PeriodLineChart';
import { retriveDashboardData } from '../api/RetrieveDashboardData';
import { redirectSessionOut } from '../api/Auth';

function DashboardPage() {
  // 데이터를 저장할 상태값
  const [salesProportionData, setSalesProportionData] = useState(null);
  const [salesVolumeProportionData, setSalesVolumeProportionData] = useState(null);
  const [lineChartsData, setLineChartsData] = useState([]);
  const [periodLineChartData, setPeriodLineChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // 데이터를 가져오는 useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dashboardData = await retriveDashboardData({
          start_date: "2024-01-01",  // 실제 날짜를 동적으로 설정할 수 있음
          end_date: "2024-12-31"
        });

        // 받아온 데이터를 각 차트에 맞게 설정
        setSalesProportionData(dashboardData.monthlySales);
        setSalesVolumeProportionData(dashboardData.monthlySalesVolume);
        setLineChartsData([
          { title: 'Monthly Sales Proportion', data: dashboardData.monthlySales },
          { title: 'Monthly Return Rate', data: dashboardData.refundCount },
          { title: 'Monthly Exchange Rate', data: dashboardData.exchangeCount }
        ]);
        setPeriodLineChartData([
          { title: 'Monthly Inventory Changes', data: dashboardData.inventoryChanges },
        ]);
        setLoading(false);
      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
        console.error('Error fetching dashboard data:', err);
        setError('Failed to load data');
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);  // 빈 배열로 설정하여 컴포넌트가 처음 렌더링될 때만 실행

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status"/>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="Page">
      <Container>
        <h1 className="my-4">Dashboard</h1>
        <Row>
          <Col md={6}>
            <Card className="m-3">
              <Card.Body>
                <Card.Title>Total Monthly Sales</Card.Title>
                {salesProportionData && <CircleChart data={salesProportionData} />}
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="m-3">
              <Card.Body>
                <Card.Title>Total Monthly Sales Volume</Card.Title>
                {salesVolumeProportionData && <CircleChart data={salesVolumeProportionData} />}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {lineChartsData.map((chart, index) => (
          <Card key={index} className="m-3">
            <Card.Body>
              <Card.Title>{chart.title}</Card.Title>
              <LineChart data={chart.data} />
            </Card.Body>
          </Card>
        ))}

        {periodLineChartData.map((chart, index) => (
          <Card key={index} className="m-3">
            <Card.Body>
              <Card.Title>{chart.title}</Card.Title>
              <PeriodLineChart data={chart.data} />
            </Card.Body>
          </Card>
        ))}

      </Container>
    </div>
  );
}

export default DashboardPage;
