import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function LineChart({ data }) {
  // labels: 12개월 라벨 생성
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // 모든 값이 0인지 확인하는 함수
  const isAllZero = (arr) => arr.every(value => value === 0);

  // data 객체에서 각 키에 해당하는 값을 데이터셋으로 변환
  const datasets = Object.keys(data)
    .filter((key) => !isAllZero(data[key])) // 모든 값이 0인 경우 필터링
    .map((key, index) => ({
      label: key,
      fill: false,
      lineTension: 0.1,
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: data[key], // 각 상품별 데이터를 사용
    }));

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  return <Line data={chartData} />;
}

export default LineChart;
