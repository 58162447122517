import React, { useState } from 'react';
import { Modal, Button, Form, Badge } from 'react-bootstrap';

const ModalFilter = ({ show, handleClose, applyFilter, selectableItems }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');

    const handleAddItem = () => {
        if (selectedOption && !selectedItems.includes(selectedOption)) {
            setSelectedItems([...selectedItems, selectedOption]);
            setSelectedOption('');
        }
    };

    const handleDeleteItem = (itemToDelete) => {
        setSelectedItems(selectedItems.filter(item => item !== itemToDelete));
    };

    const handleApplyFilter = () => {
        if (selectedItems.length === 0) {
            applyFilter(null);
        } else {
            applyFilter(selectedItems);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Options</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="filterOptions" className="d-flex align-items-center">
                        <Form.Control
                            as="select"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            className="me-2"  // 버튼과 간격을 주기 위한 클래스
                        >
                            <option value="">Select an option</option>
                            {
                                selectableItems.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </Form.Control>
                        <Button variant="primary" onClick={handleAddItem}>
                            Add
                        </Button>
                    </Form.Group>
                    <Form.Group controlId="selectedFilterOptions" className="mt-3">
                        {selectedItems.map((item, index) => (
                            <Badge
                                key={index}
                                pill
                                variant="info"
                                className="mr-2"
                                style={{ fontSize: '16px' }}
                            >
                                {item}
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => handleDeleteItem(item)}
                                    className="ml-2"
                                    style={{ fontSize: '12px' }}
                                >
                                    X
                                </Button>
                            </Badge>
                        ))}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleApplyFilter}>
                    Apply Filter
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalFilter;
