import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import Home from './pages/Home';
import Dashboard from './pages/DashboardPage';
import Prediction from './pages/PredictionPage';
import Data from './pages/DataPage';
import Stocks from './pages/StocksPage';
import Settings from './pages/SettingsPage';
import Error from './pages/ErrorPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <CustomNavbar />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/stocks" element={<Stocks />} />
            <Route path="/prediction" element={<Prediction />} />
            <Route path="/data" element={<Data />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
