import PlatformsEnum from "../constants/PlatformsEnum";

const api_url = 'https://api.pemont-insight.com/collect/sales';
let cafe_24_token = null;

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function get_size(option) {
    if (option == null) {
        return null;
    }

    const regex = new RegExp('(\\d{3,}-\\d{3,}|\\d{3,})');
    let matchobj = option[option.search(regex)];

    if (matchobj != null) {
        return matchobj;
    }


    return option;
}

async function call(content) {
    // 504 Gateway Timeout
    // 500 Internal Server Error
    // 401  Session expired
    const session_id = localStorage.getItem('session_id');
    try {
        const response = await fetch(api_url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            },
            body: content
        });

        let json = await response.json();
        if (json.hasOwnProperty("body")) {
            json = json.body;
        }
        if (json === undefined || json === null || json === "") {
            return null;
        }
        return json;
    } catch (error) {
        return "[Error] call to " + content.command + "(" + error.message + ")";
    }
}

async function get_token_cafe24(sessions, serial) {
    var session_id = sessions.ECSESSID;
    var session_id2 = sessions.PHPSESSVERIFY;

    const jsonData = {
        command: 'get_cafe24_token',
        session_id: session_id,
        session_id2: session_id2,
        serial: serial
    }

    return await call(JSON.stringify(jsonData));

}

async function upload_to_dynamodb(data) {
    const jsonData = {
        command: 'upload_to_dynamodb',
        data: data
    }

    return await call(JSON.stringify(jsonData));
}

async function get_orders_cafe24(order_id, token) {
    const jsonData = {
        command: 'get_cafe24_orders',
        order_id: order_id,
        token: token
    }

    return await call(JSON.stringify(jsonData));
}

// cafe24, Mushinsa의 아이템을 분석하게 하기
async function parse_record_cafe24(data, token, addLog) {
    const orders = data.orders;

    for (let order of orders) {
        const orders = await get_orders_cafe24(order.order_id, token);
        try {
            const orderDetails = JSON.parse(orders);
            const items = orderDetails.items;
            items.forEach(item => {
                const record = {
                    id: item.order_item_code,
                    platform: 'CAFE24',
                    date: order.order_date,
                    status: order.canceled === "T" ? '취소완료' : '정상',
                    price: order.actual_order_amount.payment_amount,
                    code: item.product_code,
                    name: item.product_name,
                    option: get_size(item.option_value)
                };
                addLog('  - 데이터 수집 : ' + record.code);
                upload_to_dynamodb(record);
                sleep(1000);
            });
        } catch (error) {
            addLog("Error: " + error.message);
            continue;
        }
    }
    return { statusCode: 200, body: 'Job Done: Parse Data (CAFE24)' };
}


// 29CM의 아이템을 가져오기 1
async function get_record_29cm(start_date, end_date, sessions, serial) {
    try {
        var session_id = sessions["_stwuid"];
        var auth_code = sessions["_fatn"];

        const jsonData = {
            command: 'get_29cm_data',
            session_id: session_id,
            serial: serial,
            auth_code: auth_code,
            start_date: start_date,
            end_date: end_date
        }

        return await call(JSON.stringify(jsonData));

    } catch (error) {
        return error.message;
    }
}

// 29CM의 아이템을 분석하게 하기
async function parse_record_29cm(serial) {
    try {
        const jsonData = {
            command: 'parse_29cm_data',
            serial: serial
        }

        return await call(JSON.stringify(jsonData));
    } catch (error) {
        return "[Error]: Parse Record 29cm (" + error.message + ")";

    }
}

// OCO의 아이템을 가져오기
async function get_record_oco(start_date, end_date, sessions, serial) {
    var session_id = sessions.JSESSIONID;

    const jsonData = {
        command: 'get_oco_data',
        session_id: session_id,
        serial: serial,
        start_date: start_date,
        end_date: end_date
    }

    return await call(JSON.stringify(jsonData));

}

// OCO의 아이템을 분석하게 하기
async function parse_record_oco(start_date, end_date, serial) {

    const jsonData = {
        command: 'parse_oco_data',
        start_date: start_date,
        end_date: end_date,
        serial: serial
    }

    return await call(JSON.stringify(jsonData));

}

// W-Concept의 아이템을 가져오기
async function get_record_w_concept(start_date, end_date, sessions, serial) {
    var session_id = sessions[".WCKPinAUTH"];

    const jsonData = {
        command: 'get_w_concept_data',
        session_id: session_id,
        serial: serial,
        start_date: start_date,
        end_date: end_date
    }

    return await call(JSON.stringify(jsonData));

}

// W-Concept의 아이템을 분석하게 하기
async function parse_record_w_concept(serial) {

    const jsonData = {
        command: 'parse_w_concept_data',
        serial: serial
    }

    return await call(JSON.stringify(jsonData));

}

// THE HANDSOME의 아이템을 가져오기
async function get_record_eql(start_date, end_date, sessions, serial) {
    var session_id = sessions.SESSION;

    const jsonData = {
        command: 'get_eql_data',
        session_id: session_id,
        serial: serial,
        start_date: start_date,
        end_date: end_date
    }

    return await call(JSON.stringify(jsonData));

}

// THE HANDSOME의 아이템을 분석하게 하기
async function parse_record_eql(serial) {

    const jsonData = {
        command: 'parse_eql_data',
        serial: serial
    }

    return await call(JSON.stringify(jsonData));

}


// KREAM의 아이템을 가져오기
async function get_record_kream(start_date, end_date, sessions, serial) {
    var session_kream = sessions.accessToken;

    const jsonData = {
        command: 'get_kream_data',
        session_id: session_kream,
        serial: serial,
        start_date: start_date,
        end_date: end_date
    }

    return await call(JSON.stringify(jsonData));

}

// KREAM의 아이템을 분석하게 하기
async function parse_record_kream(serial) {

    const jsonData = {
        command: 'parse_kream_data',
        serial: serial
    }

    return await call(JSON.stringify(jsonData));

}

async function get_record_cafe24(start_date, end_date, sessions, serial) {
    var session_id = sessions.ECSESSID;
    var session_id2 = sessions.PHPSESSVERIFY;

    const jsonData = {
        command: 'get_cafe24_data',
        session_id: session_id,
        session_id2: session_id2,
        serial: serial,
        start_date: start_date,
        end_date: end_date
    }

    return await call(JSON.stringify(jsonData));

}

export const update_sales_data = async (start_date, end_date, platforms, sessions, addLog) => {
    const serial = String(Math.round(Date.now() / 1000));
    let result = null;

    addLog("매출 데이터 수집 시작");

    for (const platform of platforms) {
        if (platform.value === PlatformsEnum.CM29.value) {
            result = await get_record_29cm(start_date, end_date, sessions[platform.value], serial);
            addLog(result);
            result = await parse_record_29cm(serial);
            addLog(result);

        } else if (platform.value === PlatformsEnum.OCO.value) {
            result = await get_record_oco(start_date, end_date, sessions[platform.value], serial);
            addLog(result);
            result = await parse_record_oco(start_date, end_date, serial);
            addLog(result);

        } else if (platform.value === PlatformsEnum.W_CONCEPT.value) {
            result = await get_record_w_concept(start_date, end_date, sessions[platform.value], serial);
            addLog(result);
            result = await parse_record_w_concept(serial);
            addLog(result);

        } else if (platform.value === PlatformsEnum.EQL.value) {
            result = await get_record_eql(start_date, end_date, sessions[platform.value], serial);
            addLog(result);
            result = await parse_record_eql(serial);
            addLog(result);

        } else if (platform.value === PlatformsEnum.KREAM.value) {
            result = await get_record_kream(start_date, end_date, sessions[platform.value], serial);
            addLog(result);
            result = await parse_record_kream(serial);
            addLog(result);

        } else if (platform.value === PlatformsEnum.CAFE24.value) {
            result = await get_token_cafe24(sessions[platform.value], serial);
            addLog("Get cafe24 token" + JSON.stringify(result));
            cafe_24_token = result.token;
            result = await get_record_cafe24(start_date, end_date, sessions[platform.value], serial);
            addLog("CAFE24 Get Data");
            result = JSON.parse(result);
            result = parse_record_cafe24(result, cafe_24_token, addLog);
            addLog(result);
        }
    }

    addLog("매출 데이터 수집 완료");
};
