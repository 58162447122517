export const removeStockFetch = async (timestamp) => {
    try {
        const session_id = localStorage.getItem('session_id');
        const query = new URLSearchParams({ timestamp: timestamp });
        const response = await fetch(`https://api.pemont-insight.com/stocks?${query.toString()}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + session_id
            }
        });
        if (response.status === 401) {
            throw new Error('Unauthorized');
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};