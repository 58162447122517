import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { Container, Table, Card, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { StockItem, StockRecord } from '../data/stocks/StocksManagement';
import PlatformsEnum from '../constants/PlatformsEnum';
import { redirectSessionOut } from '../api/Auth';
import { updateStockFetch } from '../api/UpdateStockFetch';
import { retrieveStocks } from '../api/RetrieveStocks';
import { retrieveStocksVersions } from '../api/RetrieveStocksVersions';
import { retrieveCodeSetting } from '../api/RetrieveCodeSetting';
import { removeStockFetch } from '../api/RemoveStocksFetch';

import ModalFilter from '../components/ModelFilter';

import './StocksPage.css';

const fileInputRef = React.createRef();

const normalizeStockItems = (stockItems) => {
  const allPlatforms = new Set(Object.values(PlatformsEnum).map(platform => platform.value));
  const existingPlatforms = new Set(stockItems.map(item => item.platform));
  const missingPlatforms = [...allPlatforms].filter(platform => !existingPlatforms.has(platform));
  const additionalItems = missingPlatforms.map(platform => new StockItem(platform, 0));

  return [...stockItems, ...additionalItems];
};

function Stocks() {
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [stockRecords, setStockRecords] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [stockRecordVersions, setStockRecordVersions] = useState([]);
  const [editRecordIndex, setEditRecordIndex] = useState(null);
  const [editField, setEditField] = useState('');
  const [isManualInput, setIsManualInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  //const [filteredItems, setFilteredItems] = useState(["dress (black)"]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadStockRecordVersions = async () => {
      try {
        const data = await retrieveStocksVersions();
        setStockRecordVersions(data['versions']);

        // Only set selectedTime if not already set
        if (!selectedTime && data['versions'].length > 0) {
          setSelectedTime(data['versions'][0]);
        }
      } catch (error) {
        if (error.message === 'Unauthorized') {
          redirectSessionOut(navigate);
        }
        throw error;
      }
    };

    const retrieveCodeSettingData = async () => {
      try {
        const data = await retrieveCodeSetting();
        const productOptions = data.map(item => ({
          name: item.name,
          code: item.code,
        }));
        setProductOptions(productOptions);
      } catch (err) {
        if (err.message === 'Unauthorized') {
          redirectSessionOut(navigate);
          return;
        }
      }
    };

    retrieveCodeSettingData();
    loadStockRecordVersions();
  }, [selectedTime, navigate]);

  useEffect(() => {
    if (selectedTime) {
      const loadStockData = () => {
        retrieveStocks(selectedTime).then((data) => {
          if (data === undefined || data === null || data['items'].length === 0) {
            setStockRecords([]);
            return;
          }

          const fetchedStockRecords = data['items'].map(record => StockRecord.fromData(record));
          setStockRecords(fetchedStockRecords);
          setLoading(false);
          setUpdating(false);
        }).catch((error) => {
          if (error.message === 'Unauthorized') {
            redirectSessionOut(navigate);
          }
        });
      };

      loadStockData();
    }
  }, [selectedTime, navigate]);

  const handleModalFilterShow = () => {
    setShowModalFilter(true);
  };

  const handleModalFilterClose = () => {
    setShowModalFilter(false);
  };

  const applyFilter = (items) => {
    setFilteredItems(items);
    setShowModalFilter(false);
  };

  const getSelectableItems = () => {
    if (stockRecords.length === 0) {
      return [];
    }

    return [...new Set(stockRecords.map(record => record.name))].sort();
  };

  const deleteRow = (index) => {
    const updatedStockRecords = [...stockRecords];
    updatedStockRecords.splice(index, 1);
    setStockRecords(updatedStockRecords);
  };

  const addRow = () => {
    if (selectedTime === null || selectedTime === undefined || selectedTime === '') {
      console.error("Selected time is not set.");
      return;
    }
    const currentDate = new Date(selectedTime);
    const selectedYear = currentDate.getFullYear();
    const selectedMonth = currentDate.getMonth() + 1;
    const newStockItems = Object.values(PlatformsEnum).map(platform => new StockItem(platform.value, 0));
    const newRecord = new StockRecord(uuidv4(), selectedYear, selectedMonth, '', '', 0, 0, 0, 0, newStockItems);
    setStockRecords([...stockRecords, newRecord]);
  };

  const handleClickRemoveStocks = async () => {
    if (selectedTime === null || selectedTime === undefined || selectedTime === '') {
      console.error("Selected time is not set.");
      return;
    }
    setUpdating(true);
    await removeStockFetch(selectedTime);
    setUpdating(false);
    setStockRecordVersions([...stockRecordVersions].filter(version => version !== selectedTime));
    if (stockRecordVersions.length > 0) {
      setSelectedTime(stockRecordVersions[0]);
    }
    setSelectedTime(null);
  };

  const handleProductSelect = (e) => {
    const updatedStockRecords = [...stockRecords];
    const currentRecord = updatedStockRecords[editRecordIndex];
    const selectedValue = e.target.value;

    if (selectedValue === "manual") {
      setIsManualInput(true);
    } else {
      const selectedProduct = productOptions.find(product => product.name === selectedValue);
      if (selectedProduct) {
        currentRecord.name = selectedProduct.name;
        currentRecord.code = selectedProduct.code;
      } else {
        currentRecord.name = selectedValue;
        currentRecord.code = "";
      }
      setIsManualInput(false);
    }

    setStockRecords(updatedStockRecords);
  };

  const handleFieldClick = (recordIndex, field) => {
    setEditRecordIndex(recordIndex);
    setEditField(field);
    if (field === 'name') {
      setIsManualInput(false);
    }
  };

  const handleFieldChange = (e) => {
    const updatedStockRecords = [...stockRecords];
    const currentRecord = updatedStockRecords[editRecordIndex];

    if (editField === 'code') {
      currentRecord.code = e.target.value;
    } else if (editField === 'name') {
      currentRecord.name = e.target.value;
    } else if (editField === 'size') {
      currentRecord.size = e.target.value;
    } else if (editField === 'safeStockQty') {
      currentRecord.safeStockQty = parseInt(e.target.value, 10);
    } else if (editField === 'nextOrderQty') {
      currentRecord.nextOrderQty = parseInt(e.target.value, 10);
    } else if (editField === 'offlineStockQty') {
      currentRecord.offlineStockQty = parseInt(e.target.value, 10);
    } else if (editField.startsWith('qty_')) {
      const platform = editField.split('qty_')[1];
      const currentItem = currentRecord.getStockItemByPlatform(platform);
      currentItem.qty = parseInt(e.target.value, 10);
    }

    setStockRecords(updatedStockRecords);
  };

  const handleFieldBlur = () => {
    setEditRecordIndex(null);
    setEditField('');
  };

  const handleSelectedTimeChange = (selectedTime) => {
    setUpdating(true);
    setSelectedTime(selectedTime);
  };

  const handleUpdateClick = async () => {
    if (selectedTime === null || selectedTime === undefined || selectedTime === '') {
      console.error("Selected time is not set.");
      return;
    }

    setUpdating(true);
    const time = new Date(selectedTime)
    const selectedYear = time.getFullYear();
    const selectedMonth = time.getMonth() + 1;
    const response = await updateStockFetch(stockRecords, selectedYear, selectedMonth);
    if (response && response.success) {
      console.log('Update successful:', response.data);
    } else {
      console.error('Update failed:', response.error);
    }
    setUpdating(false);

    try {
      const data = await retrieveStocksVersions();
      setStockRecordVersions(data['versions']);

      // Only set selectedTime if not already set
      if (data['versions'].length > 0) {
        setSelectedTime(data['versions'][0]);
      }
    } catch (error) {
      if (error.message === 'Unauthorized') {
        redirectSessionOut(navigate);
      }
      throw error;
    }
  };

  const handleFileChange = async (event) => {
    if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
      console.error("No file was selected or event is undefined.");
      return;
    }

    const file = event.target.files[0];

    if (!(file instanceof Blob)) {
      console.error("The selected file is not of type 'Blob'.");
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Proceed with mapping and setting stock records
      mapAndSetStockRecords(jsonData);
    };

    fileReader.onerror = (error) => {
      console.error("File could not be read: ", error);
    };

    fileReader.readAsArrayBuffer(file);
  };

  const mapAndSetStockRecords = (data) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const mappedRecords = data.map(record => {
      const stockItems = Object.keys(record)
        .filter(key => key.startsWith('qty_'))
        .map(key => new StockItem(key.replace("qty_", ""), Number(record[key])));

      return new StockRecord(
        record.id || uuidv4(),
        record.year || currentYear,
        record.month || currentMonth,
        record.name || "",
        record.code || "",
        record.size || "",
        record.offlineStockQty || 0,
        record.safeStockQty || 0,
        record.nextOrderQty || 0,
        stockItems);
    });

    setStockRecords(mappedRecords.map(record => {
      record.stockItems = normalizeStockItems(record.stockItems);
      return record;
    }));
  };


  const handleExcelDownload = async () => {
    const worksheet = XLSX.utils.json_to_sheet(stockRecords.map(record => ({
      id: record.id,
      code: record.code,
      name: record.name,
      size: record.size,
      offlineStockQty: record.offlineStockQty,
      safeStockQty: record.safeStockQty,
      nextOrderQty: record.nextOrderQty,
      ...record.stockItems.reduce((acc, item) => ({ ...acc, [`qty_${item.platform}`]: item.qty }), {}),
    })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stocks');
    XLSX.writeFile(workbook, 'stocks_data.xlsx');
  };

  // 로딩 중이거나 에러가 발생한 경우의 화면 처리
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  let stockIndex = 1;

  return (
    <div className="stocks-page">
      <Container>
        <h1 className="my-4">Stocks</h1>

        <Card className="mb-4">
          <Card.Body style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Card.Title>Stock Items</Card.Title>
            <Row className="mb-3 justify-content-end">
              <Col xs="auto">
                {updating && <Spinner animation="border" role="status" />}
                <Form.Select
                  value={selectedTime}
                  onChange={(e) => handleSelectedTimeChange(e.target.value)}
                  className="m-2"
                  style={{ display: 'inline-block', width: 'auto' }}
                >
                  {Array.from(stockRecordVersions).map(stockRecordVersion => (
                    <option key={stockRecordVersion} value={stockRecordVersion}>{stockRecordVersion}</option>
                  ))}
                </Form.Select>
                <Button variant="danger" className="m-2" onClick={handleClickRemoveStocks}>Remove</Button>
                <Button variant="primary" className="m-2" onClick={addRow}>Add Row</Button>
                <Button variant="primary" className="m-2" onClick={handleModalFilterShow}>Filter</Button>
                <Button variant="success" className="m-2" onClick={handleUpdateClick}>Update</Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <Button variant="info" className="m-2" onClick={() => fileInputRef.current.click()}>Excel Import</Button>
                <Button variant="info" className="m-2" onClick={handleExcelDownload}>Excel Download</Button>
              </Col>
            </Row>
            {stockRecords.length === 0 ? (
              <div className="text-center">
                <p>No stock data available. Please add a row or import from an Excel file.</p>
              </div>
            ) : (
              <Table striped bordered hover>
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>관리코드</th>
                    <th>제품명</th>
                    <th>사이즈</th>
                    {Object.values(PlatformsEnum).map(platform => (
                      <th key={platform.value}>{platform.displayName}</th>
                    ))}
                    <th>오프라인재고</th>
                    <th>안전재고</th>
                    <th>총재고</th>
                    <th>다음주문량</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {
                    stockRecords.map((recordItem, recordIndex) => {
                      const isNameChanged = recordIndex === 0 || recordItem.name !== stockRecords[recordIndex - 1].name;
                      const borderTop = isNameChanged ? '2px solid black' : null;
                      const display = filteredItems && !filteredItems.includes(recordItem.name) ? 'none' : null;

                      return (<tr
                        key={++stockIndex}
                        style={{ display: display, borderTop: borderTop }}
                      >
                        <td>{stockIndex}</td>
                        <td onClick={() => handleFieldClick(recordIndex, 'code')}>
                          {editRecordIndex === recordIndex && editField === 'code' ? (
                            <input
                              type="text"
                              value={recordItem.code}
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              autoFocus
                            />
                          ) : (
                            recordItem.code
                          )}
                        </td>
                        <td onClick={() => handleFieldClick(recordIndex, 'name')}>
                          {editRecordIndex === recordIndex && editField === 'name' ? (
                            isManualInput ? (
                              <input
                                type="text"
                                value={recordItem.name}
                                onChange={handleFieldChange}
                                onBlur={handleFieldBlur}
                                autoFocus
                              />
                            ) : (
                              <Form.Select
                                value={recordItem.name || "manual"}
                                onChange={handleProductSelect}
                                className="narrow-input"
                                onBlur={handleFieldBlur}
                              >
                                <option value="manual">직접 입력</option>
                                {getSelectableItems().sort().map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Select>
                            )
                          ) : (
                            recordItem.name
                          )}
                        </td>
                        <td onClick={() => handleFieldClick(recordIndex, 'size')}>
                          {editRecordIndex === recordIndex && editField === 'size' ? (
                            <input
                              type="text"
                              value={recordItem.size}
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              autoFocus
                              className="narrow-input"
                            />
                          ) : (
                            recordItem.size
                          )}
                        </td>
                        {Object.values(PlatformsEnum).map(platform => {
                          let item = recordItem.getStockItemByPlatform(platform.value);
                          if (item === undefined || item === null) {
                            item = new StockItem(platform.value, 0);
                            recordItem.stockItems.push(item);
                          }

                          const qtyClass = item.qty === 0 ? 'qty-zero' : '';

                          return (<td
                            key={platform.value}
                            onClick={() => handleFieldClick(recordIndex, `qty_${platform.value}`)}
                            className={qtyClass}
                          >
                            {editRecordIndex === recordIndex && editField === `qty_${platform.value}` ? (
                              <input
                                type="number"
                                value={item.platform === platform.value ? item.qty : 0}
                                onChange={handleFieldChange}
                                onBlur={handleFieldBlur}
                                autoFocus
                                className="narrow-input"
                              />
                            ) : (
                              item.platform === platform.value ? item.qty : 0
                            )}
                          </td>
                          );
                        })}
                        <td onClick={() => handleFieldClick(recordIndex, 'offlineStockQty')}>
                          {editRecordIndex === recordIndex && editField === 'offlineStockQty' ? (
                            <input
                              type="number"
                              value={recordItem.offlineStockQty}
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              autoFocus
                              className="narrow-input"
                            />
                          ) : (
                            recordItem.offlineStockQty
                          )}
                        </td>
                        <td onClick={() => handleFieldClick(recordIndex, 'safeStockQty')}>
                          {editRecordIndex === recordIndex && editField === 'safeStockQty' ? (
                            <input
                              type="number"
                              value={recordItem.safeStockQty}
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              autoFocus
                              className="narrow-input"
                            />
                          ) : (
                            recordItem.safeStockQty
                          )}
                        </td>
                        <td>{recordItem.stockItems.reduce((acc, item) => acc + item.qty, recordItem.safeStockQty + recordItem.offlineStockQty)}</td>
                        <td onClick={() => handleFieldClick(recordIndex, 'nextOrderQty')}>
                          {editRecordIndex === recordIndex && editField === 'nextOrderQty' ? (
                            <input
                              type="number"
                              value={recordItem.nextOrderQty}
                              onChange={handleFieldChange}
                              onBlur={handleFieldBlur}
                              autoFocus
                              className="narrow-input"
                            />
                          ) : (
                            recordItem.nextOrderQty
                          )}
                        </td>
                        <td>
                          <Button variant="danger" onClick={() => deleteRow(recordIndex)}>Delete</Button>
                        </td>
                      </tr>
                      )
                    })}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Container>
      <ModalFilter show={showModalFilter} handleClose={handleModalFilterClose} applyFilter={applyFilter} selectableItems={getSelectableItems()} />
    </div>
  );
}

export default Stocks;
